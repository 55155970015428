import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.tradeAdjustment.fields.id'),
  },
  {
    name: 'symbolId',
    label: i18n('entities.tradeAdjustment.fields.symbolId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'makerId',
    label: i18n('entities.tradeAdjustment.fields.makerId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'clientAccountId',
    label: i18n('entities.tradeAdjustment.fields.clientAccountId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'side',
    label: i18n('entities.tradeAdjustment.fields.side'),
  },
  {
    name: 'executedTime',
    label: i18n('entities.tradeAdjustment.fields.executedTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'baseVolume',
    label: i18n('entities.tradeAdjustment.fields.baseVolume'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'executedPrice',
    label: i18n('entities.tradeAdjustment.fields.executedPrice'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'comment',
    label: i18n('entities.tradeAdjustment.fields.comment'),
  },
  {
    name: 'typeId',
    label: i18n('entities.tradeAdjustment.fields.typeId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.tradeAdjustment.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.tradeAdjustment.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
