import list from 'src/modules/currencyLimit/list/currencyLimitListReducers';
import form from 'src/modules/currencyLimit/form/currencyLimitFormReducers';
import view from 'src/modules/currencyLimit/view/currencyLimitViewReducers';
import destroy from 'src/modules/currencyLimit/destroy/currencyLimitDestroyReducers';
import importerReducer from 'src/modules/currencyLimit/importer/currencyLimitImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
