import list from 'src/modules/riskyIp/list/riskyIpListReducers';
import form from 'src/modules/riskyIp/form/riskyIpFormReducers';
import view from 'src/modules/riskyIp/view/riskyIpViewReducers';
import destroy from 'src/modules/riskyIp/destroy/riskyIpDestroyReducers';
import importerReducer from 'src/modules/riskyIp/importer/riskyIpImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
