import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/symbol/importer/symbolImporterSelectors';
import SymbolService from 'src/modules/symbol/symbolService';
import fields from 'src/modules/symbol/importer/symbolImporterFields';
import { i18n } from 'src/i18n';

const symbolImporterActions = importerActions(
  'SYMBOL_IMPORTER',
  selectors,
  SymbolService.import,
  fields,
  i18n('entities.symbol.importer.fileName'),
);

export default symbolImporterActions;