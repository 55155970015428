import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/classLimit/importer/classLimitImporterSelectors';
import ClassLimitService from 'src/modules/classLimit/classLimitService';
import fields from 'src/modules/classLimit/importer/classLimitImporterFields';
import { i18n } from 'src/i18n';

const classLimitImporterActions = importerActions(
  'CLASSLIMIT_IMPORTER',
  selectors,
  ClassLimitService.import,
  fields,
  i18n('entities.classLimit.importer.fileName'),
);

export default classLimitImporterActions;