import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'eaFingerPrint',
    label: i18n('entities.riskyEa.fields.eaFingerPrint'),
    schema: schemas.string(
      i18n('entities.riskyEa.fields.eaFingerPrint'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'expiredAt',
    label: i18n('entities.riskyEa.fields.expiredAt'),
    schema: schemas.datetime(
      i18n('entities.riskyEa.fields.expiredAt'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'expiredBy',
    label: i18n('entities.riskyEa.fields.expiredBy'),
    schema: schemas.relationToOne(
      i18n('entities.riskyEa.fields.expiredBy'),
      {},
    ),
  },
];