import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/alertType/importer/alertTypeImporterSelectors';
import AlertTypeService from 'src/modules/alertType/alertTypeService';
import fields from 'src/modules/alertType/importer/alertTypeImporterFields';
import { i18n } from 'src/i18n';

const alertTypeImporterActions = importerActions(
  'ALERTTYPE_IMPORTER',
  selectors,
  AlertTypeService.import,
  fields,
  i18n('entities.alertType.importer.fileName'),
);

export default alertTypeImporterActions;