import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import makerEnumerators from 'src/modules/maker/makerEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.maker.fields.name'),
    schema: schemas.string(
      i18n('entities.maker.fields.name'),
      {
        "required": true,
        "min": 2,
        "max": 255
      },
    ),
  },
  {
    name: 'book',
    label: i18n('entities.maker.fields.book'),
    schema: schemas.enumerator(
      i18n('entities.maker.fields.book'),
      {
        "required": true,
        "options": makerEnumerators.book
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.maker.fields.description'),
    schema: schemas.string(
      i18n('entities.maker.fields.description'),
      {},
    ),
  },
];