import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/takerSymbol/importer/takerSymbolImporterSelectors';
import TakerSymbolService from 'src/modules/takerSymbol/takerSymbolService';
import fields from 'src/modules/takerSymbol/importer/takerSymbolImporterFields';
import { i18n } from 'src/i18n';

const takerSymbolImporterActions = importerActions(
  'TAKERSYMBOL_IMPORTER',
  selectors,
  TakerSymbolService.import,
  fields,
  i18n('entities.takerSymbol.importer.fileName'),
);

export default takerSymbolImporterActions;