import list from 'src/modules/caseComment/list/caseCommentListReducers';
import form from 'src/modules/caseComment/form/caseCommentFormReducers';
import view from 'src/modules/caseComment/view/caseCommentViewReducers';
import destroy from 'src/modules/caseComment/destroy/caseCommentDestroyReducers';
import importerReducer from 'src/modules/caseComment/importer/caseCommentImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
