import list from 'src/modules/caseNumber/list/caseNumberListReducers';
import form from 'src/modules/caseNumber/form/caseNumberFormReducers';
import view from 'src/modules/caseNumber/view/caseNumberViewReducers';
import destroy from 'src/modules/caseNumber/destroy/caseNumberDestroyReducers';
import importerReducer from 'src/modules/caseNumber/importer/caseNumberImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
