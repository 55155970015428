import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'category',
    label: i18n('entities.alertType.fields.category'),
    schema: schemas.string(
      i18n('entities.alertType.fields.category'),
      {
        "required": true,
        "min": 5,
        "max": 45
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.alertType.fields.name'),
    schema: schemas.string(
      i18n('entities.alertType.fields.name'),
      {
        "required": true,
        "max": 45,
        "min": 5
      },
    ),
  },
  {
    name: 'expiredAt',
    label: i18n('entities.alertType.fields.expiredAt'),
    schema: schemas.datetime(
      i18n('entities.alertType.fields.expiredAt'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'expiredBy',
    label: i18n('entities.alertType.fields.expiredBy'),
    schema: schemas.relationToOne(
      i18n('entities.alertType.fields.expiredBy'),
      {},
    ),
  },
];