import list from 'src/modules/alert/list/alertListReducers';
import form from 'src/modules/alert/form/alertFormReducers';
import view from 'src/modules/alert/view/alertViewReducers';
import destroy from 'src/modules/alert/destroy/alertDestroyReducers';
import importerReducer from 'src/modules/alert/importer/alertImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
