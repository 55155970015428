import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/riskyIp/importer/riskyIpImporterSelectors';
import RiskyIpService from 'src/modules/riskyIp/riskyIpService';
import fields from 'src/modules/riskyIp/importer/riskyIpImporterFields';
import { i18n } from 'src/i18n';

const riskyIpImporterActions = importerActions(
  'RISKYIP_IMPORTER',
  selectors,
  RiskyIpService.import,
  fields,
  i18n('entities.riskyIp.importer.fileName'),
);

export default riskyIpImporterActions;