export enum RefreshIntervals {
  REFRESH_1M = 'Refresh every 1m',
  REFRESH_5M = 'Refresh every 5m',
  REFRESH_10M = 'Refresh every 10m',
  REFRESH_15M = 'Refresh every 15m',
  REFRESH_30M = 'Refresh every 30m',
  REFRESH_1H = 'Refresh every 1h',
}

export const getRefreshInterval = (): string => {
  return (
    localStorage.getItem('refreshInterval') ||
    RefreshIntervals.REFRESH_5M
  );
};

export const setRefreshInterval = (
  interval: string,
): string => {
  localStorage.setItem('refreshInterval', interval);
  return interval;
};

export const getRefreshIntervalValue = (
  interval: string,
): number => {
  switch (interval) {
    case RefreshIntervals.REFRESH_1M:
      return 1000 * 60 * 1;
    case RefreshIntervals.REFRESH_5M:
      return 1000 * 60 * 5;
    case RefreshIntervals.REFRESH_10M:
      return 1000 * 60 * 10;
    case RefreshIntervals.REFRESH_15M:
      return 1000 * 60 * 5;
    case RefreshIntervals.REFRESH_30M:
      return 1000 * 60 * 30;
    case RefreshIntervals.REFRESH_1H:
      return 1000 * 60 * 60;
    default:
      return 1000 * 60 * 5;
  }
};
