import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/brokerSymbol/importer/brokerSymbolImporterSelectors';
import BrokerSymbolService from 'src/modules/brokerSymbol/brokerSymbolService';
import fields from 'src/modules/brokerSymbol/importer/brokerSymbolImporterFields';
import { i18n } from 'src/i18n';

const brokerSymbolImporterActions = importerActions(
  'BROKERSYMBOL_IMPORTER',
  selectors,
  BrokerSymbolService.import,
  fields,
  i18n('entities.brokerSymbol.importer.fileName'),
);

export default brokerSymbolImporterActions;