import authAxios from 'src/modules/shared/axios/authAxios';
import apiAxios from 'src/modules/shared/axios/apiAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class UserService {
  static async edit(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/user`,
      body,
    );

    return response.data;
  }

  static async destroy(rows) {
    const ids = rows.map((row) => row.id);
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/user`,
      {
        params,
      },
    );

    rows.forEach((row) => {
      this.destroyQuicksightUser(row.email);
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user`,
      body,
    );

    data.emails.forEach(async (email) => {
      this.createQuicksightUser(email);
    });

    return response.data;
  }

  static async createQuicksightUser(email: string) {
    const { data } = await apiAxios.post(
      '/quicksight/users/',
      {
        email: email,
      },
    );

    return data;
  }

  static async destroyQuicksightUser(email: string) {
    const { data } = await apiAxios.delete(
      '/quicksight/users/',
      {
        params: {
          email: email,
        },
      },
    );

    return data;
  }

  static async import(values, importHash) {
    const body = {
      data: {
        ...values,
      },
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/user/${id}`,
    );
    return response.data;
  }

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/user`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/user/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}
