import list from 'src/modules/classLimit/list/classLimitListReducers';
import form from 'src/modules/classLimit/form/classLimitFormReducers';
import view from 'src/modules/classLimit/view/classLimitViewReducers';
import destroy from 'src/modules/classLimit/destroy/classLimitDestroyReducers';
import importerReducer from 'src/modules/classLimit/importer/classLimitImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
