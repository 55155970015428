const brokerCommissionEnumerators = {
  broker: [
    'ps',
    'ic',
    'cmc',
    'cityindex',
    'ig',
    'fusion',
    'capital',
  ],
  platform: [
    'mt4',
    'mt5',
    'ctrader',
    'other',
  ],
  currency: [
    'USD',
    'AUD',
    'EUR',
    'GBP',
    'JPY',
    'CHF',
    'CAD',
    'NZD',
  ],
};

export default brokerCommissionEnumerators;
