import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import symbolEnumerators from 'src/modules/symbol/symbolEnumerators';

export default [
  {
    name: 'makerSymbol',
    label: i18n('entities.symbol.fields.makerSymbol'),
    schema: schemas.string(
      i18n('entities.symbol.fields.makerSymbol'),
      {
        "required": true,
        "max": 125,
        "min": 2
      },
    ),
  },
  {
    name: 'coreSymbol',
    label: i18n('entities.symbol.fields.coreSymbol'),
    schema: schemas.string(
      i18n('entities.symbol.fields.coreSymbol'),
      {
        "required": true,
        "min": 2,
        "max": 125
      },
    ),
  },
  {
    name: 'symbolType',
    label: i18n('entities.symbol.fields.symbolType'),
    schema: schemas.enumerator(
      i18n('entities.symbol.fields.symbolType'),
      {
        "required": true,
        "options": symbolEnumerators.symbolType
      },
    ),
  },
  {
    name: 'symbolSubType',
    label: i18n('entities.symbol.fields.symbolSubType'),
    schema: schemas.enumerator(
      i18n('entities.symbol.fields.symbolSubType'),
      {
        "required": true,
        "options": symbolEnumerators.symbolSubType
      },
    ),
  },
  {
    name: 'contractSize',
    label: i18n('entities.symbol.fields.contractSize'),
    schema: schemas.integer(
      i18n('entities.symbol.fields.contractSize'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'pipSize',
    label: i18n('entities.symbol.fields.pipSize'),
    schema: schemas.decimal(
      i18n('entities.symbol.fields.pipSize'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'ccy1',
    label: i18n('entities.symbol.fields.ccy1'),
    schema: schemas.relationToOne(
      i18n('entities.symbol.fields.ccy1'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'ccy2',
    label: i18n('entities.symbol.fields.ccy2'),
    schema: schemas.relationToOne(
      i18n('entities.symbol.fields.ccy2'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'baseCurrency',
    label: i18n('entities.symbol.fields.baseCurrency'),
    schema: schemas.relationToOne(
      i18n('entities.symbol.fields.baseCurrency'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'termCurrency',
    label: i18n('entities.symbol.fields.termCurrency'),
    schema: schemas.relationToOne(
      i18n('entities.symbol.fields.termCurrency'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'contractLimit',
    label: i18n('entities.symbol.fields.contractLimit'),
    schema: schemas.decimal(
      i18n('entities.symbol.fields.contractLimit'),
      {},
    ),
  },
  {
    name: 'notionalLimit',
    label: i18n('entities.symbol.fields.notionalLimit'),
    schema: schemas.decimal(
      i18n('entities.symbol.fields.notionalLimit'),
      {},
    ),
  },
];