import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import maker from 'src/modules/maker/makerReducers';
import alertType from 'src/modules/alertType/alertTypeReducers';
import alertSeverity from 'src/modules/alertSeverity/alertSeverityReducers';
import alertStatus from 'src/modules/alertStatus/alertStatusReducers';
import caseNumber from 'src/modules/caseNumber/caseNumberReducers';
import caseComment from 'src/modules/caseComment/caseCommentReducers';
import hedgingClass from 'src/modules/hedgingClass/hedgingClassReducers';
import currency from 'src/modules/currency/currencyReducers';
import currencyLimit from 'src/modules/currencyLimit/currencyLimitReducers';
import classLimit from 'src/modules/classLimit/classLimitReducers';
import riskyIp from 'src/modules/riskyIp/riskyIpReducers';
import riskyEa from 'src/modules/riskyEa/riskyEaReducers';
import symbol from 'src/modules/symbol/symbolReducers';
import takerSymbol from 'src/modules/takerSymbol/takerSymbolReducers';
import riskTagType from 'src/modules/riskTagType/riskTagTypeReducers';
import brokerSymbol from 'src/modules/brokerSymbol/brokerSymbolReducers';
import brokerCommission from 'src/modules/brokerCommission/brokerCommissionReducers';
import client from 'src/modules/client/clientReducers';
import clientAccount from 'src/modules/clientAccount/clientAccountReducers';
import alert from 'src/modules/alert/alertReducers';
import tradeAdjustment from 'src/modules/tradeAdjustment/tradeAdjustmentReducers';
import tradeAdjustmentType from 'src/modules/tradeAdjustmentType/tradeAdjustmentTypeReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    maker,
    alertType,
    alertSeverity,
    alertStatus,
    caseNumber,
    caseComment,
    hedgingClass,
    currency,
    currencyLimit,
    classLimit,
    riskyIp,
    riskyEa,
    symbol,
    takerSymbol,
    riskTagType,
    brokerSymbol,
    brokerCommission,
    client,
    clientAccount,
    alert,
    tradeAdjustment,
    tradeAdjustmentType,
  });
