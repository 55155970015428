import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/alertStatus/importer/alertStatusImporterSelectors';
import AlertStatusService from 'src/modules/alertStatus/alertStatusService';
import fields from 'src/modules/alertStatus/importer/alertStatusImporterFields';
import { i18n } from 'src/i18n';

const alertStatusImporterActions = importerActions(
  'ALERTSTATUS_IMPORTER',
  selectors,
  AlertStatusService.import,
  fields,
  i18n('entities.alertStatus.importer.fileName'),
);

export default alertStatusImporterActions;