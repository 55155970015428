import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/currencyLimit/importer/currencyLimitImporterSelectors';
import CurrencyLimitService from 'src/modules/currencyLimit/currencyLimitService';
import fields from 'src/modules/currencyLimit/importer/currencyLimitImporterFields';
import { i18n } from 'src/i18n';

const currencyLimitImporterActions = importerActions(
  'CURRENCYLIMIT_IMPORTER',
  selectors,
  CurrencyLimitService.import,
  fields,
  i18n('entities.currencyLimit.importer.fileName'),
);

export default currencyLimitImporterActions;