import MakerService from 'src/modules/maker/makerService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'MAKER_VIEW';

const makerViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: makerViewActions.FIND_STARTED,
      });

      const record = await MakerService.find(id);

      dispatch({
        type: makerViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: makerViewActions.FIND_ERROR,
      });

      getHistory().push('/maker');
    }
  },
};

export default makerViewActions;
