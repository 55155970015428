import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/alert/importer/alertImporterSelectors';
import AlertService from 'src/modules/alert/alertService';
import fields from 'src/modules/alert/importer/alertImporterFields';
import { i18n } from 'src/i18n';

const alertImporterActions = importerActions(
  'ALERT_IMPORTER',
  selectors,
  AlertService.import,
  fields,
  i18n('entities.alert.importer.fileName'),
);

export default alertImporterActions;