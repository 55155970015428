import list from 'src/modules/brokerSymbol/list/brokerSymbolListReducers';
import form from 'src/modules/brokerSymbol/form/brokerSymbolFormReducers';
import view from 'src/modules/brokerSymbol/view/brokerSymbolViewReducers';
import destroy from 'src/modules/brokerSymbol/destroy/brokerSymbolDestroyReducers';
import importerReducer from 'src/modules/brokerSymbol/importer/brokerSymbolImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
