import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/caseComment/importer/caseCommentImporterSelectors';
import CaseCommentService from 'src/modules/caseComment/caseCommentService';
import fields from 'src/modules/caseComment/importer/caseCommentImporterFields';
import { i18n } from 'src/i18n';

const caseCommentImporterActions = importerActions(
  'CASECOMMENT_IMPORTER',
  selectors,
  CaseCommentService.import,
  fields,
  i18n('entities.caseComment.importer.fileName'),
);

export default caseCommentImporterActions;