import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'severity',
    label: i18n('entities.alertSeverity.fields.severity'),
    schema: schemas.string(
      i18n('entities.alertSeverity.fields.severity'),
      {
        "required": true,
        "max": 45
      },
    ),
  },
];