import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/riskTagType/importer/riskTagTypeImporterSelectors';
import RiskTagTypeService from 'src/modules/riskTagType/riskTagTypeService';
import fields from 'src/modules/riskTagType/importer/riskTagTypeImporterFields';
import { i18n } from 'src/i18n';

const riskTagTypeImporterActions = importerActions(
  'RISKTAGTYPE_IMPORTER',
  selectors,
  RiskTagTypeService.import,
  fields,
  i18n('entities.riskTagType.importer.fileName'),
);

export default riskTagTypeImporterActions;