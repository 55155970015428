import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import brokerCommissionEnumerators from 'src/modules/brokerCommission/brokerCommissionEnumerators';
import moment from 'moment';

export default [
  {
    name: 'broker',
    label: i18n('entities.brokerCommission.fields.broker'),
    schema: schemas.enumerator(
      i18n('entities.brokerCommission.fields.broker'),
      {
        "options": brokerCommissionEnumerators.broker
      },
    ),
  },
  {
    name: 'platform',
    label: i18n('entities.brokerCommission.fields.platform'),
    schema: schemas.enumerator(
      i18n('entities.brokerCommission.fields.platform'),
      {
        "options": brokerCommissionEnumerators.platform
      },
    ),
  },
  {
    name: 'currency',
    label: i18n('entities.brokerCommission.fields.currency'),
    schema: schemas.enumerator(
      i18n('entities.brokerCommission.fields.currency'),
      {
        "options": brokerCommissionEnumerators.currency
      },
    ),
  },
  {
    name: 'minVolume',
    label: i18n('entities.brokerCommission.fields.minVolume'),
    schema: schemas.decimal(
      i18n('entities.brokerCommission.fields.minVolume'),
      {},
    ),
  },
  {
    name: 'maxVolume',
    label: i18n('entities.brokerCommission.fields.maxVolume'),
    schema: schemas.decimal(
      i18n('entities.brokerCommission.fields.maxVolume'),
      {},
    ),
  },
  {
    name: 'value',
    label: i18n('entities.brokerCommission.fields.value'),
    schema: schemas.decimal(
      i18n('entities.brokerCommission.fields.value'),
      {},
    ),
  },
  {
    name: 'effectiveDate',
    label: i18n('entities.brokerCommission.fields.effectiveDate'),
    schema: schemas.datetime(
      i18n('entities.brokerCommission.fields.effectiveDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'expiredDate',
    label: i18n('entities.brokerCommission.fields.expiredDate'),
    schema: schemas.datetime(
      i18n('entities.brokerCommission.fields.expiredDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
];