import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'userId',
    label: i18n('entities.clientAccount.fields.userId'),
    schema: schemas.relationToOne(
      i18n('entities.clientAccount.fields.userId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'login',
    label: i18n('entities.clientAccount.fields.login'),
    schema: schemas.integer(
      i18n('entities.clientAccount.fields.login'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'platform',
    label: i18n('entities.clientAccount.fields.platform'),
    schema: schemas.string(
      i18n('entities.clientAccount.fields.platform'),
      {
        "required": true
      },
    ),
  },
];