import RiskyIpService from 'src/modules/riskyIp/riskyIpService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'RISKYIP_VIEW';

const riskyIpViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: riskyIpViewActions.FIND_STARTED,
      });

      const record = await RiskyIpService.find(id);

      dispatch({
        type: riskyIpViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: riskyIpViewActions.FIND_ERROR,
      });

      getHistory().push('/risky-ip');
    }
  },
};

export default riskyIpViewActions;
