import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/exco',
    loader: () => import('src/view/exco/ExcoPage'),
    permissionRequired: permissions.excoRead,
    exact: true,
  },
  {
    path: '/monitoring',
    loader: () => import('src/view/riskHub/MonitoringPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/analytics',
    loader: () => import('src/view/riskHub/AnalyticsPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/maker',
    loader: () =>
      import('src/view/maker/list/MakerListPage'),
    permissionRequired: permissions.makerRead,
    exact: true,
  },
  {
    path: '/maker/new',
    loader: () =>
      import('src/view/maker/form/MakerFormPage'),
    permissionRequired: permissions.makerCreate,
    exact: true,
  },
  {
    path: '/maker/importer',
    loader: () =>
      import('src/view/maker/importer/MakerImporterPage'),
    permissionRequired: permissions.makerImport,
    exact: true,
  },
  {
    path: '/maker/:id/edit',
    loader: () =>
      import('src/view/maker/form/MakerFormPage'),
    permissionRequired: permissions.makerEdit,
    exact: true,
  },
  {
    path: '/maker/:id',
    loader: () =>
      import('src/view/maker/view/MakerViewPage'),
    permissionRequired: permissions.makerRead,
    exact: true,
  },

  {
    path: '/alert-type',
    loader: () =>
      import('src/view/alertType/list/AlertTypeListPage'),
    permissionRequired: permissions.alertTypeRead,
    exact: true,
  },
  {
    path: '/alert-type/new',
    loader: () =>
      import('src/view/alertType/form/AlertTypeFormPage'),
    permissionRequired: permissions.alertTypeCreate,
    exact: true,
  },
  {
    path: '/alert-type/importer',
    loader: () =>
      import(
        'src/view/alertType/importer/AlertTypeImporterPage'
      ),
    permissionRequired: permissions.alertTypeImport,
    exact: true,
  },
  {
    path: '/alert-type/:id/edit',
    loader: () =>
      import('src/view/alertType/form/AlertTypeFormPage'),
    permissionRequired: permissions.alertTypeEdit,
    exact: true,
  },
  {
    path: '/alert-type/:id',
    loader: () =>
      import('src/view/alertType/view/AlertTypeViewPage'),
    permissionRequired: permissions.alertTypeRead,
    exact: true,
  },

  {
    path: '/alert-severity',
    loader: () =>
      import(
        'src/view/alertSeverity/list/AlertSeverityListPage'
      ),
    permissionRequired: permissions.alertSeverityRead,
    exact: true,
  },
  {
    path: '/alert-severity/new',
    loader: () =>
      import(
        'src/view/alertSeverity/form/AlertSeverityFormPage'
      ),
    permissionRequired: permissions.alertSeverityCreate,
    exact: true,
  },
  {
    path: '/alert-severity/importer',
    loader: () =>
      import(
        'src/view/alertSeverity/importer/AlertSeverityImporterPage'
      ),
    permissionRequired: permissions.alertSeverityImport,
    exact: true,
  },
  {
    path: '/alert-severity/:id/edit',
    loader: () =>
      import(
        'src/view/alertSeverity/form/AlertSeverityFormPage'
      ),
    permissionRequired: permissions.alertSeverityEdit,
    exact: true,
  },
  {
    path: '/alert-severity/:id',
    loader: () =>
      import(
        'src/view/alertSeverity/view/AlertSeverityViewPage'
      ),
    permissionRequired: permissions.alertSeverityRead,
    exact: true,
  },

  {
    path: '/alert-status',
    loader: () =>
      import(
        'src/view/alertStatus/list/AlertStatusListPage'
      ),
    permissionRequired: permissions.alertStatusRead,
    exact: true,
  },
  {
    path: '/alert-status/new',
    loader: () =>
      import(
        'src/view/alertStatus/form/AlertStatusFormPage'
      ),
    permissionRequired: permissions.alertStatusCreate,
    exact: true,
  },
  {
    path: '/alert-status/importer',
    loader: () =>
      import(
        'src/view/alertStatus/importer/AlertStatusImporterPage'
      ),
    permissionRequired: permissions.alertStatusImport,
    exact: true,
  },
  {
    path: '/alert-status/:id/edit',
    loader: () =>
      import(
        'src/view/alertStatus/form/AlertStatusFormPage'
      ),
    permissionRequired: permissions.alertStatusEdit,
    exact: true,
  },
  {
    path: '/alert-status/:id',
    loader: () =>
      import(
        'src/view/alertStatus/view/AlertStatusViewPage'
      ),
    permissionRequired: permissions.alertStatusRead,
    exact: true,
  },

  {
    path: '/case-number',
    loader: () =>
      import('src/view/caseNumber/list/CaseNumberListPage'),
    permissionRequired: permissions.caseNumberRead,
    exact: true,
  },
  {
    path: '/case-number/new',
    loader: () =>
      import('src/view/caseNumber/form/CaseNumberFormPage'),
    permissionRequired: permissions.caseNumberCreate,
    exact: true,
  },
  {
    path: '/case-number/importer',
    loader: () =>
      import(
        'src/view/caseNumber/importer/CaseNumberImporterPage'
      ),
    permissionRequired: permissions.caseNumberImport,
    exact: true,
  },
  {
    path: '/case-number/:id/edit',
    loader: () =>
      import('src/view/caseNumber/form/CaseNumberFormPage'),
    permissionRequired: permissions.caseNumberEdit,
    exact: true,
  },
  {
    path: '/case-number/:id',
    loader: () =>
      import('src/view/caseNumber/view/CaseNumberViewPage'),
    permissionRequired: permissions.caseNumberRead,
    exact: true,
  },

  {
    path: '/case-comment',
    loader: () =>
      import(
        'src/view/caseComment/list/CaseCommentListPage'
      ),
    permissionRequired: permissions.caseCommentRead,
    exact: true,
  },
  {
    path: '/case-comment/new',
    loader: () =>
      import(
        'src/view/caseComment/form/CaseCommentFormPage'
      ),
    permissionRequired: permissions.caseCommentCreate,
    exact: true,
  },
  {
    path: '/case-comment/importer',
    loader: () =>
      import(
        'src/view/caseComment/importer/CaseCommentImporterPage'
      ),
    permissionRequired: permissions.caseCommentImport,
    exact: true,
  },
  {
    path: '/case-comment/:id/edit',
    loader: () =>
      import(
        'src/view/caseComment/form/CaseCommentFormPage'
      ),
    permissionRequired: permissions.caseCommentEdit,
    exact: true,
  },
  {
    path: '/case-comment/:id',
    loader: () =>
      import(
        'src/view/caseComment/view/CaseCommentViewPage'
      ),
    permissionRequired: permissions.caseCommentRead,
    exact: true,
  },

  {
    path: '/hedging-class',
    loader: () =>
      import(
        'src/view/hedgingClass/list/HedgingClassListPage'
      ),
    permissionRequired: permissions.hedgingClassRead,
    exact: true,
  },
  {
    path: '/hedging-class/new',
    loader: () =>
      import(
        'src/view/hedgingClass/form/HedgingClassFormPage'
      ),
    permissionRequired: permissions.hedgingClassCreate,
    exact: true,
  },
  {
    path: '/hedging-class/importer',
    loader: () =>
      import(
        'src/view/hedgingClass/importer/HedgingClassImporterPage'
      ),
    permissionRequired: permissions.hedgingClassImport,
    exact: true,
  },
  {
    path: '/hedging-class/:id/edit',
    loader: () =>
      import(
        'src/view/hedgingClass/form/HedgingClassFormPage'
      ),
    permissionRequired: permissions.hedgingClassEdit,
    exact: true,
  },
  {
    path: '/hedging-class/:id',
    loader: () =>
      import(
        'src/view/hedgingClass/view/HedgingClassViewPage'
      ),
    permissionRequired: permissions.hedgingClassRead,
    exact: true,
  },

  {
    path: '/currency',
    loader: () =>
      import('src/view/currency/list/CurrencyListPage'),
    permissionRequired: permissions.currencyRead,
    exact: true,
  },
  {
    path: '/currency/new',
    loader: () =>
      import('src/view/currency/form/CurrencyFormPage'),
    permissionRequired: permissions.currencyCreate,
    exact: true,
  },
  {
    path: '/currency/importer',
    loader: () =>
      import(
        'src/view/currency/importer/CurrencyImporterPage'
      ),
    permissionRequired: permissions.currencyImport,
    exact: true,
  },
  {
    path: '/currency/:id/edit',
    loader: () =>
      import('src/view/currency/form/CurrencyFormPage'),
    permissionRequired: permissions.currencyEdit,
    exact: true,
  },
  {
    path: '/currency/:id',
    loader: () =>
      import('src/view/currency/view/CurrencyViewPage'),
    permissionRequired: permissions.currencyRead,
    exact: true,
  },

  {
    path: '/currency-limit',
    loader: () =>
      import(
        'src/view/currencyLimit/list/CurrencyLimitListPage'
      ),
    permissionRequired: permissions.currencyLimitRead,
    exact: true,
  },
  {
    path: '/currency-limit/new',
    loader: () =>
      import(
        'src/view/currencyLimit/form/CurrencyLimitFormPage'
      ),
    permissionRequired: permissions.currencyLimitCreate,
    exact: true,
  },
  {
    path: '/currency-limit/importer',
    loader: () =>
      import(
        'src/view/currencyLimit/importer/CurrencyLimitImporterPage'
      ),
    permissionRequired: permissions.currencyLimitImport,
    exact: true,
  },
  {
    path: '/currency-limit/:id/edit',
    loader: () =>
      import(
        'src/view/currencyLimit/form/CurrencyLimitFormPage'
      ),
    permissionRequired: permissions.currencyLimitEdit,
    exact: true,
  },
  {
    path: '/currency-limit/:id',
    loader: () =>
      import(
        'src/view/currencyLimit/view/CurrencyLimitViewPage'
      ),
    permissionRequired: permissions.currencyLimitRead,
    exact: true,
  },

  {
    path: '/class-limit',
    loader: () =>
      import('src/view/classLimit/list/ClassLimitListPage'),
    permissionRequired: permissions.classLimitRead,
    exact: true,
  },
  {
    path: '/class-limit/new',
    loader: () =>
      import('src/view/classLimit/form/ClassLimitFormPage'),
    permissionRequired: permissions.classLimitCreate,
    exact: true,
  },
  {
    path: '/class-limit/importer',
    loader: () =>
      import(
        'src/view/classLimit/importer/ClassLimitImporterPage'
      ),
    permissionRequired: permissions.classLimitImport,
    exact: true,
  },
  {
    path: '/class-limit/:id/edit',
    loader: () =>
      import('src/view/classLimit/form/ClassLimitFormPage'),
    permissionRequired: permissions.classLimitEdit,
    exact: true,
  },
  {
    path: '/class-limit/:id',
    loader: () =>
      import('src/view/classLimit/view/ClassLimitViewPage'),
    permissionRequired: permissions.classLimitRead,
    exact: true,
  },

  {
    path: '/risky-ip',
    loader: () =>
      import('src/view/riskyIp/list/RiskyIpListPage'),
    permissionRequired: permissions.riskyIpRead,
    exact: true,
  },
  {
    path: '/risky-ip/new',
    loader: () =>
      import('src/view/riskyIp/form/RiskyIpFormPage'),
    permissionRequired: permissions.riskyIpCreate,
    exact: true,
  },
  {
    path: '/risky-ip/importer',
    loader: () =>
      import(
        'src/view/riskyIp/importer/RiskyIpImporterPage'
      ),
    permissionRequired: permissions.riskyIpImport,
    exact: true,
  },
  {
    path: '/risky-ip/:id/edit',
    loader: () =>
      import('src/view/riskyIp/form/RiskyIpFormPage'),
    permissionRequired: permissions.riskyIpEdit,
    exact: true,
  },
  {
    path: '/risky-ip/:id',
    loader: () =>
      import('src/view/riskyIp/view/RiskyIpViewPage'),
    permissionRequired: permissions.riskyIpRead,
    exact: true,
  },

  {
    path: '/risky-ea',
    loader: () =>
      import('src/view/riskyEa/list/RiskyEaListPage'),
    permissionRequired: permissions.riskyEaRead,
    exact: true,
  },
  {
    path: '/risky-ea/new',
    loader: () =>
      import('src/view/riskyEa/form/RiskyEaFormPage'),
    permissionRequired: permissions.riskyEaCreate,
    exact: true,
  },
  {
    path: '/risky-ea/importer',
    loader: () =>
      import(
        'src/view/riskyEa/importer/RiskyEaImporterPage'
      ),
    permissionRequired: permissions.riskyEaImport,
    exact: true,
  },
  {
    path: '/risky-ea/:id/edit',
    loader: () =>
      import('src/view/riskyEa/form/RiskyEaFormPage'),
    permissionRequired: permissions.riskyEaEdit,
    exact: true,
  },
  {
    path: '/risky-ea/:id',
    loader: () =>
      import('src/view/riskyEa/view/RiskyEaViewPage'),
    permissionRequired: permissions.riskyEaRead,
    exact: true,
  },

  {
    path: '/symbol',
    loader: () =>
      import('src/view/symbol/list/SymbolListPage'),
    permissionRequired: permissions.symbolRead,
    exact: true,
  },
  {
    path: '/symbol/new',
    loader: () =>
      import('src/view/symbol/form/SymbolFormPage'),
    permissionRequired: permissions.symbolCreate,
    exact: true,
  },
  {
    path: '/symbol/importer',
    loader: () =>
      import('src/view/symbol/importer/SymbolImporterPage'),
    permissionRequired: permissions.symbolImport,
    exact: true,
  },
  {
    path: '/symbol/:id/edit',
    loader: () =>
      import('src/view/symbol/form/SymbolFormPage'),
    permissionRequired: permissions.symbolEdit,
    exact: true,
  },
  {
    path: '/symbol/:id',
    loader: () =>
      import('src/view/symbol/view/SymbolViewPage'),
    permissionRequired: permissions.symbolRead,
    exact: true,
  },

  {
    path: '/taker-symbol',
    loader: () =>
      import(
        'src/view/takerSymbol/list/TakerSymbolListPage'
      ),
    permissionRequired: permissions.takerSymbolRead,
    exact: true,
  },
  {
    path: '/taker-symbol/new',
    loader: () =>
      import(
        'src/view/takerSymbol/form/TakerSymbolFormPage'
      ),
    permissionRequired: permissions.takerSymbolCreate,
    exact: true,
  },
  {
    path: '/taker-symbol/importer',
    loader: () =>
      import(
        'src/view/takerSymbol/importer/TakerSymbolImporterPage'
      ),
    permissionRequired: permissions.takerSymbolImport,
    exact: true,
  },
  {
    path: '/taker-symbol/:id/edit',
    loader: () =>
      import(
        'src/view/takerSymbol/form/TakerSymbolFormPage'
      ),
    permissionRequired: permissions.takerSymbolEdit,
    exact: true,
  },
  {
    path: '/taker-symbol/:id',
    loader: () =>
      import(
        'src/view/takerSymbol/view/TakerSymbolViewPage'
      ),
    permissionRequired: permissions.takerSymbolRead,
    exact: true,
  },

  {
    path: '/risk-tag-type',
    loader: () =>
      import(
        'src/view/riskTagType/list/RiskTagTypeListPage'
      ),
    permissionRequired: permissions.riskTagTypeRead,
    exact: true,
  },
  {
    path: '/risk-tag-type/new',
    loader: () =>
      import(
        'src/view/riskTagType/form/RiskTagTypeFormPage'
      ),
    permissionRequired: permissions.riskTagTypeCreate,
    exact: true,
  },
  {
    path: '/risk-tag-type/importer',
    loader: () =>
      import(
        'src/view/riskTagType/importer/RiskTagTypeImporterPage'
      ),
    permissionRequired: permissions.riskTagTypeImport,
    exact: true,
  },
  {
    path: '/risk-tag-type/:id/edit',
    loader: () =>
      import(
        'src/view/riskTagType/form/RiskTagTypeFormPage'
      ),
    permissionRequired: permissions.riskTagTypeEdit,
    exact: true,
  },
  {
    path: '/risk-tag-type/:id',
    loader: () =>
      import(
        'src/view/riskTagType/view/RiskTagTypeViewPage'
      ),
    permissionRequired: permissions.riskTagTypeRead,
    exact: true,
  },

  {
    path: '/broker-symbol',
    loader: () =>
      import(
        'src/view/brokerSymbol/list/BrokerSymbolListPage'
      ),
    permissionRequired: permissions.brokerSymbolRead,
    exact: true,
  },
  {
    path: '/broker-symbol/new',
    loader: () =>
      import(
        'src/view/brokerSymbol/form/BrokerSymbolFormPage'
      ),
    permissionRequired: permissions.brokerSymbolCreate,
    exact: true,
  },
  {
    path: '/broker-symbol/importer',
    loader: () =>
      import(
        'src/view/brokerSymbol/importer/BrokerSymbolImporterPage'
      ),
    permissionRequired: permissions.brokerSymbolImport,
    exact: true,
  },
  {
    path: '/broker-symbol/:id/edit',
    loader: () =>
      import(
        'src/view/brokerSymbol/form/BrokerSymbolFormPage'
      ),
    permissionRequired: permissions.brokerSymbolEdit,
    exact: true,
  },
  {
    path: '/broker-symbol/:id',
    loader: () =>
      import(
        'src/view/brokerSymbol/view/BrokerSymbolViewPage'
      ),
    permissionRequired: permissions.brokerSymbolRead,
    exact: true,
  },

  {
    path: '/broker-commission',
    loader: () =>
      import(
        'src/view/brokerCommission/list/BrokerCommissionListPage'
      ),
    permissionRequired: permissions.brokerCommissionRead,
    exact: true,
  },
  {
    path: '/broker-commission/new',
    loader: () =>
      import(
        'src/view/brokerCommission/form/BrokerCommissionFormPage'
      ),
    permissionRequired: permissions.brokerCommissionCreate,
    exact: true,
  },
  {
    path: '/broker-commission/importer',
    loader: () =>
      import(
        'src/view/brokerCommission/importer/BrokerCommissionImporterPage'
      ),
    permissionRequired: permissions.brokerCommissionImport,
    exact: true,
  },
  {
    path: '/broker-commission/:id/edit',
    loader: () =>
      import(
        'src/view/brokerCommission/form/BrokerCommissionFormPage'
      ),
    permissionRequired: permissions.brokerCommissionEdit,
    exact: true,
  },
  {
    path: '/broker-commission/:id',
    loader: () =>
      import(
        'src/view/brokerCommission/view/BrokerCommissionViewPage'
      ),
    permissionRequired: permissions.brokerCommissionRead,
    exact: true,
  },

  {
    path: '/client',
    loader: () =>
      import('src/view/client/list/ClientListPage'),
    permissionRequired: permissions.clientRead,
    exact: true,
  },
  {
    path: '/client/new',
    loader: () =>
      import('src/view/client/form/ClientFormPage'),
    permissionRequired: permissions.clientCreate,
    exact: true,
  },
  {
    path: '/client/importer',
    loader: () =>
      import('src/view/client/importer/ClientImporterPage'),
    permissionRequired: permissions.clientImport,
    exact: true,
  },
  {
    path: '/client/:id/edit',
    loader: () =>
      import('src/view/client/form/ClientFormPage'),
    permissionRequired: permissions.clientEdit,
    exact: true,
  },
  {
    path: '/client/:id',
    loader: () =>
      import('src/view/client/view/ClientViewPage'),
    permissionRequired: permissions.clientRead,
    exact: true,
  },

  {
    path: '/client-account',
    loader: () =>
      import(
        'src/view/clientAccount/list/ClientAccountListPage'
      ),
    permissionRequired: permissions.clientAccountRead,
    exact: true,
  },
  {
    path: '/client-account/new',
    loader: () =>
      import(
        'src/view/clientAccount/form/ClientAccountFormPage'
      ),
    permissionRequired: permissions.clientAccountCreate,
    exact: true,
  },
  {
    path: '/client-account/importer',
    loader: () =>
      import(
        'src/view/clientAccount/importer/ClientAccountImporterPage'
      ),
    permissionRequired: permissions.clientAccountImport,
    exact: true,
  },
  {
    path: '/client-account/:id/edit',
    loader: () =>
      import(
        'src/view/clientAccount/form/ClientAccountFormPage'
      ),
    permissionRequired: permissions.clientAccountEdit,
    exact: true,
  },
  {
    path: '/client-account/:id',
    loader: () =>
      import(
        'src/view/clientAccount/view/ClientAccountViewPage'
      ),
    permissionRequired: permissions.clientAccountRead,
    exact: true,
  },

  {
    path: '/risk-alert',
    loader: () =>
      import('src/view/riskAlert/RiskAlertPage'),
    permissionRequired: permissions.riskAlertRead,
    exact: true,
  },
  {
    path: '/risk-alert/new',
    loader: () =>
      import('src/view/riskAlert/form/RiskAlertFormPage'),
    permissionRequired: permissions.riskAlertCreate,
    exact: true,
  },
  {
    path: '/alert',
    loader: () =>
      import('src/view/alert/list/AlertListPage'),
    permissionRequired: permissions.alertRead,
    exact: true,
  },
  {
    path: '/alert/new',
    loader: () =>
      import('src/view/alert/form/AlertFormPage'),
    permissionRequired: permissions.alertCreate,
    exact: true,
  },
  {
    path: '/alert/importer',
    loader: () =>
      import('src/view/alert/importer/AlertImporterPage'),
    permissionRequired: permissions.alertImport,
    exact: true,
  },
  {
    path: '/alert/:id/edit',
    loader: () =>
      import('src/view/alert/form/AlertFormPage'),
    permissionRequired: permissions.alertEdit,
    exact: true,
  },
  {
    path: '/alert/:id',
    loader: () =>
      import('src/view/alert/view/AlertViewPage'),
    permissionRequired: permissions.alertRead,
    exact: true,
  },

  {
    path: '/trade-adjustment',
    loader: () =>
      import(
        'src/view/tradeAdjustment/list/TradeAdjustmentListPage'
      ),
    permissionRequired: permissions.tradeAdjustmentRead,
    exact: true,
  },
  {
    path: '/trade-adjustment/new',
    loader: () =>
      import(
        'src/view/tradeAdjustment/form/TradeAdjustmentFormPage'
      ),
    permissionRequired: permissions.tradeAdjustmentCreate,
    exact: true,
  },
  {
    path: '/trade-adjustment/importer',
    loader: () =>
      import(
        'src/view/tradeAdjustment/importer/TradeAdjustmentImporterPage'
      ),
    permissionRequired: permissions.tradeAdjustmentImport,
    exact: true,
  },
  {
    path: '/trade-adjustment/:id/edit',
    loader: () =>
      import(
        'src/view/tradeAdjustment/form/TradeAdjustmentFormPage'
      ),
    permissionRequired: permissions.tradeAdjustmentEdit,
    exact: true,
  },
  {
    path: '/trade-adjustment/:id',
    loader: () =>
      import(
        'src/view/tradeAdjustment/view/TradeAdjustmentViewPage'
      ),
    permissionRequired: permissions.tradeAdjustmentRead,
    exact: true,
  },

  {
    path: '/trade-adjustment-type',
    loader: () =>
      import(
        'src/view/tradeAdjustmentType/list/TradeAdjustmentTypeListPage'
      ),
    permissionRequired: permissions.tradeAdjustmentTypeRead,
    exact: true,
  },
  {
    path: '/trade-adjustment-type/new',
    loader: () =>
      import(
        'src/view/tradeAdjustmentType/form/TradeAdjustmentTypeFormPage'
      ),
    permissionRequired:
      permissions.tradeAdjustmentTypeCreate,
    exact: true,
  },
  {
    path: '/trade-adjustment-type/importer',
    loader: () =>
      import(
        'src/view/tradeAdjustmentType/importer/TradeAdjustmentTypeImporterPage'
      ),
    permissionRequired:
      permissions.tradeAdjustmentTypeImport,
    exact: true,
  },
  {
    path: '/trade-adjustment-type/:id/edit',
    loader: () =>
      import(
        'src/view/tradeAdjustmentType/form/TradeAdjustmentTypeFormPage'
      ),
    permissionRequired: permissions.tradeAdjustmentTypeEdit,
    exact: true,
  },
  {
    path: '/trade-adjustment-type/:id',
    loader: () =>
      import(
        'src/view/tradeAdjustmentType/view/TradeAdjustmentTypeViewPage'
      ),
    permissionRequired: permissions.tradeAdjustmentTypeRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
