import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import clientEnumerators from 'src/modules/client/clientEnumerators';

export default [
  {
    name: 'userId',
    label: i18n('entities.client.fields.userId'),
    schema: schemas.integer(
      i18n('entities.client.fields.userId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'license',
    label: i18n('entities.client.fields.license'),
    schema: schemas.enumerator(
      i18n('entities.client.fields.license'),
      {
        "required": true,
        "options": clientEnumerators.license
      },
    ),
  },
  {
    name: 'country',
    label: i18n('entities.client.fields.country'),
    schema: schemas.string(
      i18n('entities.client.fields.country'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'region',
    label: i18n('entities.client.fields.region'),
    schema: schemas.string(
      i18n('entities.client.fields.region'),
      {
        "required": true
      },
    ),
  },
];