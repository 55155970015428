import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/alertSeverity/importer/alertSeverityImporterSelectors';
import AlertSeverityService from 'src/modules/alertSeverity/alertSeverityService';
import fields from 'src/modules/alertSeverity/importer/alertSeverityImporterFields';
import { i18n } from 'src/i18n';

const alertSeverityImporterActions = importerActions(
  'ALERTSEVERITY_IMPORTER',
  selectors,
  AlertSeverityService.import,
  fields,
  i18n('entities.alertSeverity.importer.fileName'),
);

export default alertSeverityImporterActions;