import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.brokerSymbol.fields.id'),
  },
  {
    name: 'broker',
    label: i18n('entities.brokerSymbol.fields.broker'),
  },
  {
    name: 'platform',
    label: i18n('entities.brokerSymbol.fields.platform'),
  },
  {
    name: 'license',
    label: i18n('entities.brokerSymbol.fields.license'),
  },
  {
    name: 'account',
    label: i18n('entities.brokerSymbol.fields.account'),
  },
  {
    name: 'accountType',
    label: i18n('entities.brokerSymbol.fields.accountType'),
  },
  {
    name: 'symbolId',
    label: i18n('entities.brokerSymbol.fields.symbolId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'brokerSymbol',
    label: i18n('entities.brokerSymbol.fields.brokerSymbol'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.brokerSymbol.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.brokerSymbol.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
