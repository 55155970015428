import list from 'src/modules/alertType/list/alertTypeListReducers';
import form from 'src/modules/alertType/form/alertTypeFormReducers';
import view from 'src/modules/alertType/view/alertTypeViewReducers';
import destroy from 'src/modules/alertType/destroy/alertTypeDestroyReducers';
import importerReducer from 'src/modules/alertType/importer/alertTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
