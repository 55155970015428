import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useState } from 'react';
import {
  getRefreshInterval,
  RefreshIntervals,
} from 'src/modules/shared/helper';
import actions from 'src/modules/layout/layoutActions';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
  },
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

function RefreshSelect(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const doUpdateRefreshInterval = (interval: string) => {
    actions.doUpdateRefreshInterval(interval);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
      >
        <RefreshIcon />
        <span className={classes.text}>
          {getRefreshInterval()}
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(RefreshIntervals).map((key) => (
          <MenuItem
            key={key}
            onClick={(event) =>
              doUpdateRefreshInterval(RefreshIntervals[key])
            }
          >
            {RefreshIntervals[key]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default RefreshSelect;
