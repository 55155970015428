import AlertStatusService from 'src/modules/alertStatus/alertStatusService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'ALERTSTATUS_VIEW';

const alertStatusViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: alertStatusViewActions.FIND_STARTED,
      });

      const record = await AlertStatusService.find(id);

      dispatch({
        type: alertStatusViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: alertStatusViewActions.FIND_ERROR,
      });

      getHistory().push('/alert-status');
    }
  },
};

export default alertStatusViewActions;
