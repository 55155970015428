import list from 'src/modules/tradeAdjustment/list/tradeAdjustmentListReducers';
import form from 'src/modules/tradeAdjustment/form/tradeAdjustmentFormReducers';
import view from 'src/modules/tradeAdjustment/view/tradeAdjustmentViewReducers';
import destroy from 'src/modules/tradeAdjustment/destroy/tradeAdjustmentDestroyReducers';
import importerReducer from 'src/modules/tradeAdjustment/importer/tradeAdjustmentImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
