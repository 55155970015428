import list from 'src/modules/tradeAdjustmentType/list/tradeAdjustmentTypeListReducers';
import form from 'src/modules/tradeAdjustmentType/form/tradeAdjustmentTypeFormReducers';
import view from 'src/modules/tradeAdjustmentType/view/tradeAdjustmentTypeViewReducers';
import destroy from 'src/modules/tradeAdjustmentType/destroy/tradeAdjustmentTypeDestroyReducers';
import importerReducer from 'src/modules/tradeAdjustmentType/importer/tradeAdjustmentTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
