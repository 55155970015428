import TradeAdjustmentService from 'src/modules/tradeAdjustment/tradeAdjustmentService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'TRADEADJUSTMENT_VIEW';

const tradeAdjustmentViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: tradeAdjustmentViewActions.FIND_STARTED,
      });

      const record = await TradeAdjustmentService.find(id);

      dispatch({
        type: tradeAdjustmentViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tradeAdjustmentViewActions.FIND_ERROR,
      });

      getHistory().push('/trade-adjustment');
    }
  },
};

export default tradeAdjustmentViewActions;
