import list from 'src/modules/alertSeverity/list/alertSeverityListReducers';
import form from 'src/modules/alertSeverity/form/alertSeverityFormReducers';
import view from 'src/modules/alertSeverity/view/alertSeverityViewReducers';
import destroy from 'src/modules/alertSeverity/destroy/alertSeverityDestroyReducers';
import importerReducer from 'src/modules/alertSeverity/importer/alertSeverityImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
