import React, { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import { useDispatch, useSelector } from 'react-redux';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  toolbar: theme.mixins.toolbar,
  listItemIcon: {
    minWidth: '48px',
  },
  listItemDisabled: {
    opacity: 0.5,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function Menu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );
  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  );

  useLayoutEffect(() => {
    const toggleMenuOnResize = () => {
      (window as any).innerWidth < 576
        ? dispatch(actions.doHideMenu())
        : dispatch(actions.doShowMenu());
    };

    toggleMenuOnResize();

    (window as any).addEventListener(
      'resize',
      toggleMenuOnResize,
    );

    return () => {
      (window as any).removeEventListener(
        'resize',
        toggleMenuOnResize,
      );
    };
  }, [dispatch]);

  const selectedKeys = () => {
    const url = props.url;

    const match = menus.find((option) => {
      if (option.exact) {
        return url === option.path;
      }

      return (
        url === option.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      return [match.path];
    }

    return [];
  };

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  const lockedForCurrentPlan = (permission) => {
    return permissionChecker.lockedForCurrentPlan(
      permission,
    );
  };

  const CustomRouterLink = (props) => (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <Link
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
        {...props}
      />
    </div>
  );

  const NestedList = (props) => {
    const menu = props.menu;
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
      setOpen(!open);
    };
    return (
      <React.Fragment key={menu.path}>
        <ListItem button onClick={handleClick}>
          <ListItemIcon
            className={clsx({
              [classes.listItemIcon]: true,
              [classes.active]: selectedKeys().includes(
                menu.path,
              ),
            })}
          >
            {menu.icon}
          </ListItemIcon>
          <ListItemText>{menu.label}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menu.children.map((child) => (
              <CustomRouterLink
                key={child.path}
                to={child.path}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon
                    className={clsx({
                      [classes.listItemIcon]: true,
                      [classes.active]:
                        selectedKeys().includes(child.path),
                    })}
                  >
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={clsx({
                      [classes.active]:
                        selectedKeys().includes(child.path),
                    })}
                  >
                    {child.label}
                  </ListItemText>
                </ListItem>
              </CustomRouterLink>
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  if (!menuVisible) {
    return null;
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar}></div>
      <List>
        {menus
          .filter((menu) => match(menu.permissionRequired))
          .map((menu) =>
            menu.children ? (
              <NestedList
                menu={menu}
                key={menu.path}
              ></NestedList>
            ) : (
              <CustomRouterLink
                key={menu.path}
                to={menu.path}
              >
                <ListItem button>
                  <ListItemIcon
                    className={clsx({
                      [classes.listItemIcon]: true,
                      [classes.active]:
                        selectedKeys().includes(menu.path),
                    })}
                  >
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={clsx({
                      [classes.active]:
                        selectedKeys().includes(menu.path),
                    })}
                  >
                    {menu.label}
                  </ListItemText>
                </ListItem>
              </CustomRouterLink>
            ),
          )}

        {menus
          .filter((menu) =>
            lockedForCurrentPlan(menu.permissionRequired),
          )
          .map((menu) => (
            <ListItem
              key={menu.path}
              className={classes.listItemDisabled}
            >
              <ListItemIcon
                className={classes.listItemIcon}
              >
                {menu.icon}
              </ListItemIcon>
              <ListItemText>{menu.label}</ListItemText>
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
}

export default Menu;
