import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.brokerCommission.fields.id'),
  },
  {
    name: 'broker',
    label: i18n('entities.brokerCommission.fields.broker'),
  },
  {
    name: 'platform',
    label: i18n('entities.brokerCommission.fields.platform'),
  },
  {
    name: 'currency',
    label: i18n('entities.brokerCommission.fields.currency'),
  },
  {
    name: 'minVolume',
    label: i18n('entities.brokerCommission.fields.minVolume'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'maxVolume',
    label: i18n('entities.brokerCommission.fields.maxVolume'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'value',
    label: i18n('entities.brokerCommission.fields.value'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'effectiveDate',
    label: i18n('entities.brokerCommission.fields.effectiveDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'expiredDate',
    label: i18n('entities.brokerCommission.fields.expiredDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.brokerCommission.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.brokerCommission.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
