const symbolEnumerators = {
  symbolType: [
    'Fx',
    'Commodity',
    'Equity',
    'EquityIndex',
    'Futures',
    'CryptoCurrency',
  ],
  symbolSubType: [
    'MajorFxSpot',
    'MinorFxSpot',
    'ExoticFxSpot',
    'FxIndexSpot',
    'EnergySpot',
    'AgriculturalFutures',
    'BaseMetalsFutures',
    'PreciousMetalsSpot',
    'SpotEquityNASDAQ',
    'SpotEquityUK',
    'SpotEquityNYSE',
    'SpotEquityDE',
    'SpotEquityCHIA',
    'SpotIndexMajor',
    'MajorEquityIndexSpot',
    'SpotIndexMinor',
    'MinorEquityIndexSpot',
    'NasdaqEquitySpot',
    'SpotEquityNYSEARCA',
    'CryptoCurrencySpot',
    'SpotEquityHKEX'
  ],
};

export default symbolEnumerators;
