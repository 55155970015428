// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `https://api.excalibur.pepperstone.com/api`;

const websocketUrl = `wss://nest-api.excalibur.pepperstone.com:4001`;

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden
const apiDocumentationUrl = `/documentation`;

const apiUrl =
  'https://kpr7h10qk1.execute-api.us-east-1.amazonaws.com/prod';
/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'excalibur.pepperstone.com',
  protocol: 'https',
};

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'multi-with-subdomain';

/**
 * Plan payments configuration.
 */
const isPlanEnabled = false;
const stripePublishableKey = '';

export default {
  frontendUrl,
  backendUrl,
  apiDocumentationUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey,
  apiUrl,
  websocketUrl,
};
