import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.currencyLimit.fields.id'),
  },
  {
    name: 'currencyId',
    label: i18n('entities.currencyLimit.fields.currencyId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contractLimit',
    label: i18n('entities.currencyLimit.fields.contractLimit'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'notionalLimit',
    label: i18n('entities.currencyLimit.fields.notionalLimit'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.currencyLimit.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.currencyLimit.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
