import TradeAdjustmentTypeService from 'src/modules/tradeAdjustmentType/tradeAdjustmentTypeService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'TRADEADJUSTMENTTYPE_VIEW';

const tradeAdjustmentTypeViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: tradeAdjustmentTypeViewActions.FIND_STARTED,
      });

      const record = await TradeAdjustmentTypeService.find(id);

      dispatch({
        type: tradeAdjustmentTypeViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tradeAdjustmentTypeViewActions.FIND_ERROR,
      });

      getHistory().push('/trade-adjustment-type');
    }
  },
};

export default tradeAdjustmentTypeViewActions;
