import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/maker/importer/makerImporterSelectors';
import MakerService from 'src/modules/maker/makerService';
import fields from 'src/modules/maker/importer/makerImporterFields';
import { i18n } from 'src/i18n';

const makerImporterActions = importerActions(
  'MAKER_IMPORTER',
  selectors,
  MakerService.import,
  fields,
  i18n('entities.maker.importer.fileName'),
);

export default makerImporterActions;