import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import alertEnumerators from 'src/modules/alert/alertEnumerators';
import moment from 'moment';

export default [
  {
    name: 'alertType',
    label: i18n('entities.alert.fields.alertType'),
    schema: schemas.relationToOne(
      i18n('entities.alert.fields.alertType'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'eventTs',
    label: i18n('entities.alert.fields.eventTs'),
    schema: schemas.datetime(
      i18n('entities.alert.fields.eventTs'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'login',
    label: i18n('entities.alert.fields.login'),
    schema: schemas.relationToOne(
      i18n('entities.alert.fields.login'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'alertSeverity',
    label: i18n('entities.alert.fields.alertSeverity'),
    schema: schemas.relationToOne(
      i18n('entities.alert.fields.alertSeverity'),
      {},
    ),
  },
  {
    name: 'caseId',
    label: i18n('entities.alert.fields.caseId'),
    schema: schemas.relationToOne(
      i18n('entities.alert.fields.caseId'),
      {},
    ),
  },
  {
    name: 'statusId',
    label: i18n('entities.alert.fields.statusId'),
    schema: schemas.relationToOne(
      i18n('entities.alert.fields.statusId'),
      {},
    ),
  },
  {
    name: 'profilingKey',
    label: i18n('entities.alert.fields.profilingKey'),
    schema: schemas.string(
      i18n('entities.alert.fields.profilingKey'),
      {},
    ),
  },
  {
    name: 'source',
    label: i18n('entities.alert.fields.source'),
    schema: schemas.enumerator(
      i18n('entities.alert.fields.source'),
      {
        "required": true,
        "options": alertEnumerators.source
      },
    ),
  },
  {
    name: 'details',
    label: i18n('entities.alert.fields.details'),
    schema: schemas.string(
      i18n('entities.alert.fields.details'),
      {},
    ),
  },
];