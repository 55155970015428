import list from 'src/modules/hedgingClass/list/hedgingClassListReducers';
import form from 'src/modules/hedgingClass/form/hedgingClassFormReducers';
import view from 'src/modules/hedgingClass/view/hedgingClassViewReducers';
import destroy from 'src/modules/hedgingClass/destroy/hedgingClassDestroyReducers';
import importerReducer from 'src/modules/hedgingClass/importer/hedgingClassImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
