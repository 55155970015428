import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.alert.fields.id'),
  },
  {
    name: 'alertType',
    label: i18n('entities.alert.fields.alertType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'eventTs',
    label: i18n('entities.alert.fields.eventTs'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'login',
    label: i18n('entities.alert.fields.login'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'alertSeverity',
    label: i18n('entities.alert.fields.alertSeverity'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'caseId',
    label: i18n('entities.alert.fields.caseId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'statusId',
    label: i18n('entities.alert.fields.statusId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'profilingKey',
    label: i18n('entities.alert.fields.profilingKey'),
  },
  {
    name: 'source',
    label: i18n('entities.alert.fields.source'),
  },
  {
    name: 'details',
    label: i18n('entities.alert.fields.details'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.alert.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.alert.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
