import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import caseNumberEnumerators from 'src/modules/caseNumber/caseNumberEnumerators';

export default [
  {
    name: 'status',
    label: i18n('entities.caseNumber.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.caseNumber.fields.status'),
      {
        "required": true,
        "options": caseNumberEnumerators.status
      },
    ),
  },
  {
    name: 'riskTags',
    label: i18n('entities.caseNumber.fields.riskTags'),
    schema: schemas.relationToMany(
      i18n('entities.caseNumber.fields.riskTags'),
      {},
    ),
  },
];