import list from 'src/modules/riskyEa/list/riskyEaListReducers';
import form from 'src/modules/riskyEa/form/riskyEaFormReducers';
import view from 'src/modules/riskyEa/view/riskyEaViewReducers';
import destroy from 'src/modules/riskyEa/destroy/riskyEaDestroyReducers';
import importerReducer from 'src/modules/riskyEa/importer/riskyEaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
