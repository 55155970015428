import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import brokerSymbolEnumerators from 'src/modules/brokerSymbol/brokerSymbolEnumerators';

export default [
  {
    name: 'broker',
    label: i18n('entities.brokerSymbol.fields.broker'),
    schema: schemas.enumerator(
      i18n('entities.brokerSymbol.fields.broker'),
      {
        "options": brokerSymbolEnumerators.broker
      },
    ),
  },
  {
    name: 'platform',
    label: i18n('entities.brokerSymbol.fields.platform'),
    schema: schemas.enumerator(
      i18n('entities.brokerSymbol.fields.platform'),
      {
        "options": brokerSymbolEnumerators.platform
      },
    ),
  },
  {
    name: 'license',
    label: i18n('entities.brokerSymbol.fields.license'),
    schema: schemas.enumerator(
      i18n('entities.brokerSymbol.fields.license'),
      {
        "options": brokerSymbolEnumerators.license
      },
    ),
  },
  {
    name: 'account',
    label: i18n('entities.brokerSymbol.fields.account'),
    schema: schemas.string(
      i18n('entities.brokerSymbol.fields.account'),
      {},
    ),
  },
  {
    name: 'accountType',
    label: i18n('entities.brokerSymbol.fields.accountType'),
    schema: schemas.enumerator(
      i18n('entities.brokerSymbol.fields.accountType'),
      {
        "options": brokerSymbolEnumerators.accountType
      },
    ),
  },
  {
    name: 'symbolId',
    label: i18n('entities.brokerSymbol.fields.symbolId'),
    schema: schemas.relationToOne(
      i18n('entities.brokerSymbol.fields.symbolId'),
      {},
    ),
  },
  {
    name: 'brokerSymbol',
    label: i18n('entities.brokerSymbol.fields.brokerSymbol'),
    schema: schemas.string(
      i18n('entities.brokerSymbol.fields.brokerSymbol'),
      {},
    ),
  },
];