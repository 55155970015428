import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'symbolId',
    label: i18n('entities.takerSymbol.fields.symbolId'),
    schema: schemas.relationToOne(
      i18n('entities.takerSymbol.fields.symbolId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'takerSymbol',
    label: i18n('entities.takerSymbol.fields.takerSymbol'),
    schema: schemas.string(
      i18n('entities.takerSymbol.fields.takerSymbol'),
      {
        "required": true
      },
    ),
  },
];