import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'classId',
    label: i18n('entities.classLimit.fields.classId'),
    schema: schemas.relationToOne(
      i18n('entities.classLimit.fields.classId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'contractLimit',
    label: i18n('entities.classLimit.fields.contractLimit'),
    schema: schemas.decimal(
      i18n('entities.classLimit.fields.contractLimit'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'notionalLimit',
    label: i18n('entities.classLimit.fields.notionalLimit'),
    schema: schemas.decimal(
      i18n('entities.classLimit.fields.notionalLimit'),
      {
        "required": true
      },
    ),
  },
];