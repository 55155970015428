import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import tradeAdjustmentEnumerators from 'src/modules/tradeAdjustment/tradeAdjustmentEnumerators';
import moment from 'moment';

export default [
  {
    name: 'symbolId',
    label: i18n('entities.tradeAdjustment.fields.symbolId'),
    schema: schemas.relationToOne(
      i18n('entities.tradeAdjustment.fields.symbolId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'makerId',
    label: i18n('entities.tradeAdjustment.fields.makerId'),
    schema: schemas.relationToOne(
      i18n('entities.tradeAdjustment.fields.makerId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'clientAccountId',
    label: i18n('entities.tradeAdjustment.fields.clientAccountId'),
    schema: schemas.relationToOne(
      i18n('entities.tradeAdjustment.fields.clientAccountId'),
      {},
    ),
  },
  {
    name: 'side',
    label: i18n('entities.tradeAdjustment.fields.side'),
    schema: schemas.enumerator(
      i18n('entities.tradeAdjustment.fields.side'),
      {
        "options": tradeAdjustmentEnumerators.side
      },
    ),
  },
  {
    name: 'executedTime',
    label: i18n('entities.tradeAdjustment.fields.executedTime'),
    schema: schemas.datetime(
      i18n('entities.tradeAdjustment.fields.executedTime'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'baseVolume',
    label: i18n('entities.tradeAdjustment.fields.baseVolume'),
    schema: schemas.decimal(
      i18n('entities.tradeAdjustment.fields.baseVolume'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'executedPrice',
    label: i18n('entities.tradeAdjustment.fields.executedPrice'),
    schema: schemas.decimal(
      i18n('entities.tradeAdjustment.fields.executedPrice'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'comment',
    label: i18n('entities.tradeAdjustment.fields.comment'),
    schema: schemas.string(
      i18n('entities.tradeAdjustment.fields.comment'),
      {},
    ),
  },
  {
    name: 'typeId',
    label: i18n('entities.tradeAdjustment.fields.typeId'),
    schema: schemas.relationToOne(
      i18n('entities.tradeAdjustment.fields.typeId'),
      {
        "required": true
      },
    ),
  },
];