import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'currencyId',
    label: i18n('entities.currencyLimit.fields.currencyId'),
    schema: schemas.relationToOne(
      i18n('entities.currencyLimit.fields.currencyId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'contractLimit',
    label: i18n('entities.currencyLimit.fields.contractLimit'),
    schema: schemas.decimal(
      i18n('entities.currencyLimit.fields.contractLimit'),
      {
        "min": 0,
        "required": true
      },
    ),
  },
  {
    name: 'notionalLimit',
    label: i18n('entities.currencyLimit.fields.notionalLimit'),
    schema: schemas.decimal(
      i18n('entities.currencyLimit.fields.notionalLimit'),
      {
        "required": true
      },
    ),
  },
];