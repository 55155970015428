import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.symbol.fields.id'),
  },
  {
    name: 'makerSymbol',
    label: i18n('entities.symbol.fields.makerSymbol'),
  },
  {
    name: 'coreSymbol',
    label: i18n('entities.symbol.fields.coreSymbol'),
  },
  {
    name: 'symbolType',
    label: i18n('entities.symbol.fields.symbolType'),
  },
  {
    name: 'symbolSubType',
    label: i18n('entities.symbol.fields.symbolSubType'),
  },
  {
    name: 'contractSize',
    label: i18n('entities.symbol.fields.contractSize'),
  },
  {
    name: 'pipSize',
    label: i18n('entities.symbol.fields.pipSize'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'ccy1',
    label: i18n('entities.symbol.fields.ccy1'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'ccy2',
    label: i18n('entities.symbol.fields.ccy2'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'baseCurrency',
    label: i18n('entities.symbol.fields.baseCurrency'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'termCurrency',
    label: i18n('entities.symbol.fields.termCurrency'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contractLimit',
    label: i18n('entities.symbol.fields.contractLimit'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'notionalLimit',
    label: i18n('entities.symbol.fields.notionalLimit'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.symbol.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.symbol.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
