import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tradeAdjustmentType/importer/tradeAdjustmentTypeImporterSelectors';
import TradeAdjustmentTypeService from 'src/modules/tradeAdjustmentType/tradeAdjustmentTypeService';
import fields from 'src/modules/tradeAdjustmentType/importer/tradeAdjustmentTypeImporterFields';
import { i18n } from 'src/i18n';

const tradeAdjustmentTypeImporterActions = importerActions(
  'TRADEADJUSTMENTTYPE_IMPORTER',
  selectors,
  TradeAdjustmentTypeService.import,
  fields,
  i18n('entities.tradeAdjustmentType.importer.fileName'),
);

export default tradeAdjustmentTypeImporterActions;