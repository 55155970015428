import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/hedgingClass/importer/hedgingClassImporterSelectors';
import HedgingClassService from 'src/modules/hedgingClass/hedgingClassService';
import fields from 'src/modules/hedgingClass/importer/hedgingClassImporterFields';
import { i18n } from 'src/i18n';

const hedgingClassImporterActions = importerActions(
  'HEDGINGCLASS_IMPORTER',
  selectors,
  HedgingClassService.import,
  fields,
  i18n('entities.hedgingClass.importer.fileName'),
);

export default hedgingClassImporterActions;