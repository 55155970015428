import list from 'src/modules/symbol/list/symbolListReducers';
import form from 'src/modules/symbol/form/symbolFormReducers';
import view from 'src/modules/symbol/view/symbolViewReducers';
import destroy from 'src/modules/symbol/destroy/symbolDestroyReducers';
import importerReducer from 'src/modules/symbol/importer/symbolImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
