import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import BarChartIcon from '@material-ui/icons/BarChart';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import config from 'src/config';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: permissions.dashboardRead,
  },
  {
    path: '/risk-hub',
    exact: true,
    icon: <TrendingDownIcon />,
    label: i18n('riskHub.menu'),
    permissionRequired: permissions.riskHubRead,
    children: [
      {
        path: '/monitoring',
        exact: true,
        icon: <DesktopWindowsIcon />,
        label: i18n('monitoring.menu'),
      },
      {
        path: '/analytics',
        exact: true,
        icon: <BarChartIcon />,
        label: i18n('analytics.menu'),
      },
    ],
  },
  {
    path: '/exco',
    exact: true,
    icon: <BarChartIcon />,
    label: i18n('exco.menu'),
    permissionRequired: permissions.excoRead,
  },
  {
    path: '/risk-alert',
    permissionRequired: permissions.alertRead,
    icon: <ChevronRightIcon />,
    label: i18n('entities.riskAlert.menu'),
  },

  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planRead,
    icon: <CreditCardOutlinedIcon />,
    label: i18n('plan.menu'),
  },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },

  {
    path: '/audit-logs',
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '',
    icon: <SettingsIcon />,
    label: i18n('configuration.menu'),
    permissionRequired: permissions.configurationRead,
    children: [
      {
        path: '/maker',
        permissionRequired: permissions.makerRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.maker.menu'),
      },

      {
        path: '/alert-type',
        permissionRequired: permissions.alertTypeRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.alertType.menu'),
      },

      {
        path: '/alert-severity',
        permissionRequired: permissions.alertSeverityRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.alertSeverity.menu'),
      },

      {
        path: '/alert-status',
        permissionRequired: permissions.alertStatusRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.alertStatus.menu'),
      },

      {
        path: '/case-number',
        permissionRequired: permissions.caseNumberRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.caseNumber.menu'),
      },

      {
        path: '/case-comment',
        permissionRequired: permissions.caseCommentRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.caseComment.menu'),
      },

      {
        path: '/hedging-class',
        permissionRequired: permissions.hedgingClassRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.hedgingClass.menu'),
      },

      {
        path: '/currency',
        permissionRequired: permissions.currencyRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.currency.menu'),
      },

      {
        path: '/currency-limit',
        permissionRequired: permissions.currencyLimitRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.currencyLimit.menu'),
      },

      {
        path: '/class-limit',
        permissionRequired: permissions.classLimitRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.classLimit.menu'),
      },

      {
        path: '/risky-ip',
        permissionRequired: permissions.riskyIpRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.riskyIp.menu'),
      },

      {
        path: '/risky-ea',
        permissionRequired: permissions.riskyEaRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.riskyEa.menu'),
      },

      {
        path: '/symbol',
        permissionRequired: permissions.symbolRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.symbol.menu'),
      },

      {
        path: '/taker-symbol',
        permissionRequired: permissions.takerSymbolRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.takerSymbol.menu'),
      },

      {
        path: '/risk-tag-type',
        permissionRequired: permissions.riskTagTypeRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.riskTagType.menu'),
      },

      {
        path: '/broker-symbol',
        permissionRequired: permissions.brokerSymbolRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.brokerSymbol.menu'),
      },

      {
        path: '/broker-commission',
        permissionRequired:
          permissions.brokerCommissionRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.brokerCommission.menu'),
      },

      {
        path: '/client',
        permissionRequired: permissions.clientRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.client.menu'),
      },

      {
        path: '/client-account',
        permissionRequired: permissions.clientAccountRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.clientAccount.menu'),
      },

      {
        path: '/alert',
        permissionRequired: permissions.alertRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.alert.menu'),
      },

      {
        path: '/trade-adjustment',
        permissionRequired: permissions.tradeAdjustmentRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.tradeAdjustment.menu'),
      },

      {
        path: '/trade-adjustment-type',
        permissionRequired:
          permissions.tradeAdjustmentTypeRead,
        icon: <ChevronRightIcon />,
        label: i18n('entities.tradeAdjustmentType.menu'),
      },
    ],
  },
].filter(Boolean);
