import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tradeAdjustment/importer/tradeAdjustmentImporterSelectors';
import TradeAdjustmentService from 'src/modules/tradeAdjustment/tradeAdjustmentService';
import fields from 'src/modules/tradeAdjustment/importer/tradeAdjustmentImporterFields';
import { i18n } from 'src/i18n';

const tradeAdjustmentImporterActions = importerActions(
  'TRADEADJUSTMENT_IMPORTER',
  selectors,
  TradeAdjustmentService.import,
  fields,
  i18n('entities.tradeAdjustment.importer.fileName'),
);

export default tradeAdjustmentImporterActions;