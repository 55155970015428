import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      excoRead: {
        id: 'ExcoRead',
        allowedRoles: [
          roles.exco,
          roles.admin,
          roles.app,
          roles.custom,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles['operator'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      dashboardRead: {
        id: 'DashboardRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles.custom,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles['operator'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      configurationRead: {
        id: 'ConfigurationRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles.custom,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles['operator'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskHubRead: {
        id: 'RiskHubRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles.custom,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles['operator'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      makerImport: {
        id: 'makerImport',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      makerCreate: {
        id: 'makerCreate',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      makerEdit: {
        id: 'makerEdit',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      makerDestroy: {
        id: 'makerDestroy',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      makerRead: {
        id: 'makerRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-analyst'],
          roles['risk-manager'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      makerAutocomplete: {
        id: 'makerAutocomplete',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      alertTypeImport: {
        id: 'alertTypeImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      alertTypeCreate: {
        id: 'alertTypeCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertTypeEdit: {
        id: 'alertTypeEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertTypeDestroy: {
        id: 'alertTypeDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertTypeRead: {
        id: 'alertTypeRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      alertTypeAutocomplete: {
        id: 'alertTypeAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      alertSeverityImport: {
        id: 'alertSeverityImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      alertSeverityCreate: {
        id: 'alertSeverityCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertSeverityEdit: {
        id: 'alertSeverityEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertSeverityDestroy: {
        id: 'alertSeverityDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertSeverityRead: {
        id: 'alertSeverityRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      alertSeverityAutocomplete: {
        id: 'alertSeverityAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      alertStatusImport: {
        id: 'alertStatusImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      alertStatusCreate: {
        id: 'alertStatusCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertStatusEdit: {
        id: 'alertStatusEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertStatusDestroy: {
        id: 'alertStatusDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertStatusRead: {
        id: 'alertStatusRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      alertStatusAutocomplete: {
        id: 'alertStatusAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      caseNumberImport: {
        id: 'caseNumberImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      caseNumberCreate: {
        id: 'caseNumberCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      caseNumberEdit: {
        id: 'caseNumberEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      caseNumberDestroy: {
        id: 'caseNumberDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      caseNumberRead: {
        id: 'caseNumberRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      caseNumberAutocomplete: {
        id: 'caseNumberAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      caseCommentImport: {
        id: 'caseCommentImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      caseCommentCreate: {
        id: 'caseCommentCreate',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.caseCommentAttachment],
      },
      caseCommentEdit: {
        id: 'caseCommentEdit',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.caseCommentAttachment],
      },
      caseCommentDestroy: {
        id: 'caseCommentDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.caseCommentAttachment],
      },
      caseCommentRead: {
        id: 'caseCommentRead',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      caseCommentAutocomplete: {
        id: 'caseCommentAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      hedgingClassImport: {
        id: 'hedgingClassImport',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      hedgingClassCreate: {
        id: 'hedgingClassCreate',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      hedgingClassEdit: {
        id: 'hedgingClassEdit',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      hedgingClassDestroy: {
        id: 'hedgingClassDestroy',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      hedgingClassRead: {
        id: 'hedgingClassRead',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      hedgingClassAutocomplete: {
        id: 'hedgingClassAutocomplete',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      currencyImport: {
        id: 'currencyImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      currencyCreate: {
        id: 'currencyCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      currencyEdit: {
        id: 'currencyEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      currencyDestroy: {
        id: 'currencyDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      currencyRead: {
        id: 'currencyRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      currencyAutocomplete: {
        id: 'currencyAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      currencyLimitImport: {
        id: 'currencyLimitImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      currencyLimitCreate: {
        id: 'currencyLimitCreate',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      currencyLimitEdit: {
        id: 'currencyLimitEdit',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      currencyLimitDestroy: {
        id: 'currencyLimitDestroy',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      currencyLimitRead: {
        id: 'currencyLimitRead',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      currencyLimitAutocomplete: {
        id: 'currencyLimitAutocomplete',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      classLimitImport: {
        id: 'classLimitImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      classLimitCreate: {
        id: 'classLimitCreate',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      classLimitEdit: {
        id: 'classLimitEdit',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      classLimitDestroy: {
        id: 'classLimitDestroy',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      classLimitRead: {
        id: 'classLimitRead',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      classLimitAutocomplete: {
        id: 'classLimitAutocomplete',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      riskyIpImport: {
        id: 'riskyIpImport',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskyIpCreate: {
        id: 'riskyIpCreate',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskyIpEdit: {
        id: 'riskyIpEdit',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskyIpDestroy: {
        id: 'riskyIpDestroy',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskyIpRead: {
        id: 'riskyIpRead',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskyIpAutocomplete: {
        id: 'riskyIpAutocomplete',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      riskyEaImport: {
        id: 'riskyEaImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskyEaCreate: {
        id: 'riskyEaCreate',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskyEaEdit: {
        id: 'riskyEaEdit',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskyEaDestroy: {
        id: 'riskyEaDestroy',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskyEaRead: {
        id: 'riskyEaRead',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskyEaAutocomplete: {
        id: 'riskyEaAutocomplete',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      symbolImport: {
        id: 'symbolImport',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      symbolCreate: {
        id: 'symbolCreate',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      symbolEdit: {
        id: 'symbolEdit',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      symbolDestroy: {
        id: 'symbolDestroy',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      symbolRead: {
        id: 'symbolRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      symbolAutocomplete: {
        id: 'symbolAutocomplete',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      takerSymbolImport: {
        id: 'takerSymbolImport',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      takerSymbolCreate: {
        id: 'takerSymbolCreate',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      takerSymbolEdit: {
        id: 'takerSymbolEdit',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      takerSymbolDestroy: {
        id: 'takerSymbolDestroy',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      takerSymbolRead: {
        id: 'takerSymbolRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-analyst'],
          roles['risk-manager'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      takerSymbolAutocomplete: {
        id: 'takerSymbolAutocomplete',
        allowedRoles: [roles.admin, roles.app],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      riskTagTypeImport: {
        id: 'riskTagTypeImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskTagTypeCreate: {
        id: 'riskTagTypeCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskTagTypeEdit: {
        id: 'riskTagTypeEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskTagTypeDestroy: {
        id: 'riskTagTypeDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskTagTypeRead: {
        id: 'riskTagTypeRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskTagTypeAutocomplete: {
        id: 'riskTagTypeAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      brokerSymbolImport: {
        id: 'brokerSymbolImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      brokerSymbolCreate: {
        id: 'brokerSymbolCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      brokerSymbolEdit: {
        id: 'brokerSymbolEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      brokerSymbolDestroy: {
        id: 'brokerSymbolDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      brokerSymbolRead: {
        id: 'brokerSymbolRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      brokerSymbolAutocomplete: {
        id: 'brokerSymbolAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      brokerCommissionImport: {
        id: 'brokerCommissionImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      brokerCommissionCreate: {
        id: 'brokerCommissionCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      brokerCommissionEdit: {
        id: 'brokerCommissionEdit',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      brokerCommissionDestroy: {
        id: 'brokerCommissionDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      brokerCommissionRead: {
        id: 'brokerCommissionRead',
        allowedRoles: [
          roles.admin,
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      brokerCommissionAutocomplete: {
        id: 'brokerCommissionAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      clientImport: {
        id: 'clientImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      clientCreate: {
        id: 'clientCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientEdit: {
        id: 'clientEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientDestroy: {
        id: 'clientDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientRead: {
        id: 'clientRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      clientAutocomplete: {
        id: 'clientAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      clientAccountImport: {
        id: 'clientAccountImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      clientAccountCreate: {
        id: 'clientAccountCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientAccountEdit: {
        id: 'clientAccountEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientAccountDestroy: {
        id: 'clientAccountDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      clientAccountRead: {
        id: 'clientAccountRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      clientAccountAutocomplete: {
        id: 'clientAccountAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      alertImport: {
        id: 'alertImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      riskAlertCreate: {
        id: 'riskAlertCreate',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      riskAlertRead: {
        id: 'riskAlertRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertCreate: {
        id: 'alertCreate',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertEdit: {
        id: 'alertEdit',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertDestroy: {
        id: 'alertDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      alertRead: {
        id: 'alertRead',
        allowedRoles: [
          roles.admin,
          roles.app,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      alertAutocomplete: {
        id: 'alertAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      tradeAdjustmentImport: {
        id: 'tradeAdjustmentImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tradeAdjustmentCreate: {
        id: 'tradeAdjustmentCreate',
        allowedRoles: [roles.admin, roles['risk-manager']],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      tradeAdjustmentEdit: {
        id: 'tradeAdjustmentEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      tradeAdjustmentDestroy: {
        id: 'tradeAdjustmentDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      tradeAdjustmentRead: {
        id: 'tradeAdjustmentRead',
        allowedRoles: [
          roles.admin,
          roles['risk-manager'],
          roles['risk-analyst'],
          roles.operator,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tradeAdjustmentAutocomplete: {
        id: 'tradeAdjustmentAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      tradeAdjustmentTypeImport: {
        id: 'tradeAdjustmentTypeImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tradeAdjustmentTypeCreate: {
        id: 'tradeAdjustmentTypeCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      tradeAdjustmentTypeEdit: {
        id: 'tradeAdjustmentTypeEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      tradeAdjustmentTypeDestroy: {
        id: 'tradeAdjustmentTypeDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      tradeAdjustmentTypeRead: {
        id: 'tradeAdjustmentTypeRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tradeAdjustmentTypeAutocomplete: {
        id: 'tradeAdjustmentTypeAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
