import list from 'src/modules/riskTagType/list/riskTagTypeListReducers';
import form from 'src/modules/riskTagType/form/riskTagTypeFormReducers';
import view from 'src/modules/riskTagType/view/riskTagTypeViewReducers';
import destroy from 'src/modules/riskTagType/destroy/riskTagTypeDestroyReducers';
import importerReducer from 'src/modules/riskTagType/importer/riskTagTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
