import list from 'src/modules/maker/list/makerListReducers';
import form from 'src/modules/maker/form/makerFormReducers';
import view from 'src/modules/maker/view/makerViewReducers';
import destroy from 'src/modules/maker/destroy/makerDestroyReducers';
import importerReducer from 'src/modules/maker/importer/makerImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
