import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/caseNumber/importer/caseNumberImporterSelectors';
import CaseNumberService from 'src/modules/caseNumber/caseNumberService';
import fields from 'src/modules/caseNumber/importer/caseNumberImporterFields';
import { i18n } from 'src/i18n';

const caseNumberImporterActions = importerActions(
  'CASENUMBER_IMPORTER',
  selectors,
  CaseNumberService.import,
  fields,
  i18n('entities.caseNumber.importer.fileName'),
);

export default caseNumberImporterActions;