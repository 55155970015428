const brokerSymbolEnumerators = {
  broker: [
    'ps',
    'ic',
    'ig',
    'cmc',
    'xm',
    'eightcap',
    'cityindex',
    'fusion',
  ],
  platform: [
    'mt4',
    'mt5',
    'ctrader',
    'other',
  ],
  license: [
    'asic',
    'fca',
    'cma',
    'bafin',
    'dfsa',
    'scb',
  ],
  accountType: [
    'pro',
    'retail',
  ],
};

export default brokerSymbolEnumerators;
