import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/riskyEa/importer/riskyEaImporterSelectors';
import RiskyEaService from 'src/modules/riskyEa/riskyEaService';
import fields from 'src/modules/riskyEa/importer/riskyEaImporterFields';
import { i18n } from 'src/i18n';

const riskyEaImporterActions = importerActions(
  'RISKYEA_IMPORTER',
  selectors,
  RiskyEaService.import,
  fields,
  i18n('entities.riskyEa.importer.fileName'),
);

export default riskyEaImporterActions;