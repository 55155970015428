import list from 'src/modules/takerSymbol/list/takerSymbolListReducers';
import form from 'src/modules/takerSymbol/form/takerSymbolFormReducers';
import view from 'src/modules/takerSymbol/view/takerSymbolViewReducers';
import destroy from 'src/modules/takerSymbol/destroy/takerSymbolDestroyReducers';
import importerReducer from 'src/modules/takerSymbol/importer/takerSymbolImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
